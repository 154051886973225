import React, { useState, useContext, useEffect } from "react";
import { TopicContext } from "../TopicContext";

export default function Title(slide) {
  const {
    topicType,
    // eslint-disable-next-line
    setTopicType
  } = useContext(TopicContext);

  const [introText, setIntroText] = useState("");
  useEffect(() => {
     setIntroText(
    topicType === "gut-brain-axis"
      ? slide.data.slides[0].splash
      : topicType === "probiotics"
      ? slide.data.slides[2].splash
      : topicType === "prebiotics"
      ? slide.data.slides[4].splash
      : topicType === "postbiotics"
      ? slide.data.slides[6].splash
      : topicType === "colostrum"
      ? slide.data.slides[8].splash
      : ""
  );
  
    return () => {}
    
  }, [slide.data.slides, topicType])
  
  const handleClick = () => {
    document.getElementById("central-media-container").classList.toggle("fade-out");
    document.getElementById("primary").classList.toggle("is-visible")
  };
  return (
    <div id="central-media-container">
      <div className="title-slide" onClick={handleClick} id={topicType}>
        <h2>{introText}</h2>
      </div>
      <button className="switch-panel right" onClick={handleClick}>
        <img
          src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Right%20Arrow.png"
          alt="next page"
        />
      </button>
    </div>
  );
}
